import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PharmacyService } from '../services/pharmacy.service';
import { WebQueryService } from '../services/web-query.service';
import { GlobalConstants } from '../shared/global-constants';
import { Pharmacy } from '../shared/models/pharmacy.model';
import { SiteConfigTpe } from '../shared/models/site.config';
declare var $: any;
@Component({
  selector: 'app-new-home-page',
  templateUrl: './new-home-page.component.html',
  styleUrls: ['./new-home-page.component.scss']
})
export class NewHomePageComponent implements OnInit {

  // console.log("1) Dusk Pharmacy, 2) Pulse Pharmacy, 3) Dukh Bhanjan Pharmacy 4)Dukh Bhanjan Pharmacy, 5)Father Tobin Pharmacy,
  //  6)Sandalwood Care, 7) Fandor Pharmacy, 8) Squire ellis Pharmacy",this.pharmacies);
  showPharmacy = ['Dusk Pharmacy',
    'Pulse Pharmacy',
    'Dukh Bhanjan Pharmacy',
    'West Brampton Pharmacy',
    'Father Tobin Pharmacy',
    'Sandalwood Care',
    'Fandor Pharmacy',
    'Squire ellis Pharmacy',
  ]

  ASYMPTOMATIC_TEST_PRICE = `PCR Testing 24-48 hrs $125 (+tax) <br />Same day result: $250 (+tax). <br />Rapid antigen test for
                    travel:
                    $40 (Incl tax).`;
  zoom = 10;
  center: google.maps.LatLngLiteral;
  pharmacies: Array<Pharmacy> | undefined;
  copyPharmacies: Array<Pharmacy> | undefined;

  selected8pharmacy: object = {};
  selected8pharmacy2: Array<object> | undefined;
  markers: Array<any> | undefined;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    maxZoom: 15,
    minZoom: 8

  }
  currentService: any;
  currentPageURL: string = '';

  constructor(private webQueryService: WebQueryService,
    private pharmacyService: PharmacyService) {
    this.center = {
      lat: 43.815373,
      lng: -79.7315907,
    }
  }
  queryForm = {
    name: "",
    email: "",
    mobileNum: "",
    message: ""
  }
  pharmaciesForMaps: Array<any> | undefined;
  ngOnInit(): void {

    this.markers = [];

    this.pharmacyService.getAllMyLocarxEnable(false).subscribe(data => {
      //  this.pharmacies = data.filter(x => x.myLocalRXEnabled == true);
      //  console.log(this.pharmacies)

      this.pharmacies = data.filter(x => x.name == 'DUSK PHARMACY' || x.name == 'PULSE PHARMACY' || x.name == 'DUKH BHANJAN PHARMACY'
        || x.name == 'WEST BRAMPTON PHARMACY'
        || x.name == 'FATHER TOBIN PHARMACY'
        || x.name == 'SANDALWOOD CARE PHARMACY'
        || x.name == 'FANDOR PHARMACY'
        || x.name == 'SQUIRE ELLIS PHARMACY');
      this.copyPharmacies = this.pharmacies;
      console.log(this.copyPharmacies)
      if (this.pharmacies) {
        this.pharmaciesForMaps = [];
        var i = 0;
        data.forEach(element => {
          if (i % 3 == 0) {
            var d = {};
            this.pharmaciesForMaps!.push({ d: [] });
          }
          this.pharmaciesForMaps![this.pharmaciesForMaps!.length - 1].d.push(element);
          i++;
        });
        for (let entry of this.pharmacies) {
          if (entry.latitude && entry.longitude) {
            this.markers!.push({
              position: {
                lat: entry.latitude,
                lng: entry.longitude,
              },
              label: {
                color: 'red',
                text: entry.name,
              },
              title: entry.name,
              options: { animation: google.maps.Animation.BOUNCE },
            })
          }
          else {
            var address = entry.name + " " + entry.add1 + " " + entry.city + " " + entry.state + " " + entry.pin;
            this.pharmacyService.getLatLonByAddress(address).then(result => {

              this.markers!.push({
                position: result,
                label: {
                  color: 'red',
                  text: entry.name,
                },
                title: entry.name,
                options: { animation: google.maps.Animation.BOUNCE },
              })
            });
          }
        }
      }






      $('.csLoader').hide();
    }, error => $('.csLoader').hide());


    var siteConfigInterval = setInterval(() => {
      var config = localStorage.getItem(GlobalConstants.SITE_CONFIG) ? JSON.parse(localStorage.getItem(GlobalConstants.SITE_CONFIG)!) : null;
      var element = document.getElementById("asymp-confirmation");
      if (config && element) {
        var val = config.find((m: any) => m.active && m.configType == SiteConfigTpe.ASYMPTOMATIC_TEST_PRICE);
        this.ASYMPTOMATIC_TEST_PRICE = val.value.replace(new RegExp('\r?\n', 'g'), '<br />');
      }
    }, 1000);

  }
  thankYouUser = "";
  saveQuery(webQueryForm: NgForm) {
    if (webQueryForm.form.invalid) {
      for (const field in webQueryForm.form.controls) {
        const control = webQueryForm.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
          $('#' + field).focus();
          break;
        }
      }
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    this.webQueryService.save(this.queryForm).subscribe(x => {
      this.thankYouUser = x.name;
      this.queryForm = { name: "", email: "", message: "", mobileNum: "" };
      $('.csLoader').hide();
      $("#modal-thank-you").modal("toggle");
      for (const field in webQueryForm.form.controls) {
        const control = webQueryForm.form.get(field);
        control?.markAsUntouched();
      }
    }, error => {
      $('.csLoader').hide();
    });
  }
  showUl() {
    $('.t21s').toggleClass('orscO');

  }
  showUl1() {

    $('.t21s1').toggleClass('orscO');

  }

  serviceName(value: any) {
    this.currentService = ''
    // console.log('value=',value)
    this.currentService = value;
    localStorage.setItem("selectedService", this.currentService);
  }

  search = "";
  filterPharmacies() {

    this.copyPharmacies = this.pharmacies?.filter(x =>
      x.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
      x.state.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
      x.pin.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
      x.city.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
  }
}
