<div class="main">
<div class="container mt-5 mb-10">
  <div class="row">
    <!-- <div class="col-12 col-md-5 col-lg-6 order-md-2 border p-5 rounded order-2">
      <div class="row">
        <div class="col-xl-12">
          <h3 class="display-4 text-center text-md-left">
            Register for fast and safe delivery at your door step!
          </h3>
        </div>
        <div class="col-xl-12">
          <div class="py-3 border-dashed mb-3 text-center">
            <img src="./../../assets/svg/img 10.svg" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-12 col-md-5 col-lg-6 order-md-2 border p-5 rounded order-1">
      <!-- Sign Up - Code - Starts -->
      <form *ngIf="!signIN && !forgotPaass" class="form-signup" #signupForm="ngForm" (ngSubmit)="signup(signupForm)">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <input id="firstName" name="firstName" #firstName [(ngModel)]="newUser.firstName"
                class="form-control form-control-sm" required pattern=".{2,}" placeholder="First Name*" />
              <span>*</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input id="lastName" name="lastName" #lastName [(ngModel)]="newUser.lastName"
                class="form-control form-control-sm" required pattern=".{1,}" placeholder="Last Name*" />
              <span>*</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div style="position: relative;" class="form-group">
              <!-- <input appInputMask [regx]="'int'" [format]="'##/##/####'" style="font-size: 0.9rem;"
                placeholder="Date of birth" data-provide="datepicker" data-date-autoclose="true"
                data-date-format="mm/dd/yyyy" data-date-end-date="-1d" id="dateOfBirth" name="dateOfBirth" #dateOfBirth
                [(ngModel)]="newUser.dateOfBirth" class="form-control agedatepicker1" required minlength="10"
                maxlength="10" /> -->

              <input appInputMask [regx]="'int'" [format]="'##/##/####'" style="font-size: 0.9rem;"
                placeholder="Date of birth MM/DD/YYYY" id="dateOfBirth" name="dateOfBirth" #dateOfBirth
                [(ngModel)]="newUser.dateOfBirth" class="form-control agedatepicker1" required minlength="10"
                maxlength="10" />
              <span>*</span>
              <label style="width: 15px; cursor: default !important;" class="agedatepickerIcon" for="dateOfBirth"><i style="float: right;"
                  class="fa fa-calendar"></i></label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="radio" name="sex" id="male" [value]="0" [(ngModel)]="newUser.sex" />
              <label style="padding-left: 5px; padding-right: 30px" for="male">Male</label>
              <input type="radio" name="sex" id="female" [value]="1" [(ngModel)]="newUser.sex" />
              <label style="padding-left: 5px" for="female">Female</label>
              <span>*</span>
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
          <select name="bloodGroup"  [(ngModel)]="newUser.bloodGroup"  pattern=".{1,}" class="form-control form-control-sm" required>
            <option selected disabled  value="">Blood group</option>
            <option value="0">O Positive</option>
            <option value="1">O Negative</option>
            <option value="2">A Positive</option>
            <option value="3">A Negative</option>
            <option value="4">B Positive</option>
            <option value="5">B Negative</option>
            <option value="6">AB Positive</option>
            <option value="7">AB Negative</option>
          </select>
          <span>*</span>
        </div> -->
        <!--<div class="form-group bloogGroup">
          <legend>
            <fieldset>
              <legend>Blood group</legend>
            </fieldset>
            <div class="legendD">
              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="OPositive"
                  [value]="0"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="OPositive">O+</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="ONegative"
                  [value]="1"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="ONegative">O-</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="APositive"
                  [value]="2"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="APositive">A+</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="ANegative"
                  [value]="3"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="ANegative">A-</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="BPositive"
                  [value]="4"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="BPositive">B+</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="BNegative"
                  [value]="5"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="BNegative">B-</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="ABPositive"
                  [value]="6"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="ABPositive">AB+</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="bloodGroup"
                  id="ABNegative"
                  [value]="7"
                  [(ngModel)]="newUser.bloodGroup"
                />
                <label for="ABNegative">AB-</label>
              </div>
            </div>
          </legend>
        </div>-->
        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <input id="phoneNumber" type="tel" name="phoneNumber" #phoneNumber [(ngModel)]="newUser.phoneNumber"
                class="form-control form-control-sm" appPhoneMask placeholder="Mobile Number*" required minlength="16"
                maxlength="17" />
              <span>*</span>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-check">
              <input type="checkbox" name="allowSMSNotication" id="allowSMSNotication"
                [(ngModel)]="newUser.allowSMSNotification" class="form-check-input" />
              <label style="  cursor: pointer;font-size: 0.9rem;" class="form-check-label" for="allowSMSNotication">
                Allow SMS Notification
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <input id="email" type="email" name="email" placeholder="E-mail Address" #email
                [(ngModel)]="newUser.email" class="form-control form-control-sm"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
              <span>*</span>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-check">
              <input type="checkbox" name="allowEmailNotication" id="allowEmailNotication"
                [(ngModel)]="newUser.allowEmailNotification" class="form-check-input" />
              <label style="  cursor: pointer;font-size: 0.9rem;" class="form-check-label" for="allowEmailNotication">
                Allow E-mail Notification
              </label>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <input id="password" type="password" name="password" #password placeholder="Enter Password*"
                [(ngModel)]="newUser.password" class="form-control form-control-sm" required pattern=".{4,}"
                minlength="8" maxlength="14" />
              <span>*</span>
              <small id="passwordHelpBlock" class="form-text text-muted">
                Your password must be 8-14 characters long and must not contain
                spaces.
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input [(ngModel)]="newUser.confirmPassword" type="password" id="confirmPassword" #confirmPassword
                validateEqual="password" name="confirmPassword" class="form-control form-control-sm"
                placeholder="Confirm Password*" required pattern=".{4,}" />
              <span>*</span>
            </div>
          </div>
        </div>



        <div class="form-group">
          <input id="ohip" placeholder="OHIP Number (Optional)" maxlength="15" name="ohip" appInputMask
            [format]="'####-###-###-##'" #ohip [(ngModel)]="newUser.ohip" class="form-control form-control-sm" />
        </div>
        <div class="form-group">
          <input id="ohipFile" (change)="fileChangeEvent($event,'ohipFile')" name="ohipFiles" type="file" multiple />
          <!-- <small id="ohipFilesHelpBLock" class="form-text text-muted">
            To capture a clear and complete photo of the OHIP, make sure your
            OHIP is flat and well illuminated.
          </small> -->
        </div>

        <div class="form-group">
          <div>
            <input style="cursor: pointer" type="checkbox" name="haveinsurance" id="haveinsurance" [value]="false"
              [(ngModel)]="newUser.haveinsurance" />
            <label style="padding-left: 10px; cursor: pointer" for="haveinsurance">I have insurance card.</label>
          </div>
          <div [ngClass]="newUser.haveinsurance ? '' : 'hide'">
            <input (change)="fileChangeEvent($event,'insuranceFile')" id="insuranceFile" name="insuranceFiles"
              type="file" multiple />
            <small id="insuranceFilesHelpBLock" class="form-text text-muted">
              To capture a clear and complete photo of the insurance card, make
              sure your insurance card is flat and well illuminated.
            </small>
          </div>
        </div>

        <div class="form-group">
          <label for="selectedPharmacyID">Preferred Pharmacy</label>
          <select name="preferredPharmacy" required class="form-control" [(ngModel)]="newUser.preferredPharmacy"
            id="selectedPharmacyID" name="selectedPharmacy" #selectedPharmacy
            (change)="onPharmacySelect(selectedPharmacy.value)">
            <option selected disabled value="">Select pharmacy</option>
            <option *ngFor="let pharmacy of pharmacies" [value]="pharmacy.id">
              {{ pharmacy.name }}
            </option>
          </select>
          <small id="pharmacyAddressBlock" class="form-text text-muted">
            {{ selectedPharmacyAddress }}
          </small>
        </div>

        <div *ngIf="signupForm.form.invalid && signupForm.form.touched" class="form-label-group">
          <div style="padding: 4px 10px" class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>
              <i style="padding-right: 10px" class="fa fa fa-exclamation-triangle"></i>
            </strong>
            <label style="margin: 0; font-size: 0.9rem">
              Please fill all mandatory fields</label>
          </div>
        </div>
        <!--<p *ngFor="let storehour of this.selectedPharmacy.storeHours">
                      {{storehour.weekDayName}} : {{storehour.startTime}} - {{storehour.endTime}} 
                    </p>-->
        <button class="btn btn-primary mt-3 btn-block" type="submit" style="color: white;">
          Sign Up
        </button>
        <label style="float: right; cursor: pointer; padding-top: 10px" on-click="openSignINForm()">
          <a href="javascript:void(0)">Already a User? Sign In </a>
        </label>
      </form>
      <!--{{} Sign Un - Code - Ends -->

      <!-- Sign In - Code - Starts -->
      <form *ngIf="signIN && !forgotPaass" class="form-signin" #signinForm="ngForm" (ngSubmit)="signin(signinForm)">
        <div class="form-group">
          <label for="inputEmail">E-mail Address or Phone Number </label>
          <!-- <input type="email" name="email" #email id="inputEmail" [(ngModel)]="newUser.email" class="form-control"
            placeholder="Enter your e-mail address" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            autofocus /> -->
          <input type="tex" name="email" #email id="inputEmail" [(ngModel)]="newUser.email" class="form-control"
            placeholder="Enter your e-mail or phone number" required min="6" minlength="6" autofocus />
          <span>*</span>
        </div>

        <div class="form-group">
          <label for="inputPassword">Password </label>
          <input type="password" name="password" id="inputPassword" #password [(ngModel)]="newUser.password"
            class="form-control" placeholder="Enter your password" required pattern=".{4,}"
            title="Four or more characters" />
          <span>*</span>
        </div>

        <div class="custom-control custom-checkbox mb-3">
          <!-- <input type="checkbox" class="custom-control-input" id="customCheck1" />
                    <label class="custom-control-label" for="customCheck1">Remember Password</label> -->
          <label style="float: right; cursor: pointer">
            <a (click)="forgotPaass = true" href="javascript:void(0)">Forgot Password?</a>
          </label>
        </div>
        <div *ngIf="showLoginError" class="form-label-group">
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>
              <i class="fa fa fa-exclamation-triangle"></i>
            </strong>
            Invalid email or password
            <button type="button" class="close">
              <span (click)="showLoginError = false" aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      
        <button class="btn btn-primary mt-3 btn-block" type="submit" style="color: white;" data-toggle="modal" data-target="#popUpWindow">
          Sign In
        </button>
        <!-- Don't Delete - Social Media Login Starts" -->
        <!-- <hr class="my-4" /> -->
        <!-- <button
                    class="btn btn-lg btn-google btn-block text-uppercase"
                    type="submit"
                  >
                    <i class="fab fa-google mr-2"></i>
                    Google
                  </button> -->
        <!-- <button
                    class="btn btn-lg btn-facebook btn-block text-uppercase"
                    type="submit"
                  >
                    <i class="fab fa-facebook-f mr-2"></i>
                    Facebook
                  </button> -->
        <!-- Don't Delete Social Media Login Ends -->
        <label style="float: right; cursor: pointer; padding-top: 10px" on-click="openSignUPForm()">
          <a href="javascript:void(0)">New User? Sign up</a>
        </label>
      </form>
      <!-- Sign In - Code - End -->

      <!-- Forgot - Code - Starts -->
      <form *ngIf="forgotPaass" class="form-signin" #forgotPasswordForm="ngForm"
        (ngSubmit)="forgotPassword(forgotPasswordForm)">
        <div class="form-group">
          <label for="inputEmail">E-mail Address or Phone Number </label>
          <input (focus)="resetPasswordError = false" type="text" name="email" #email id="inputEmail" [(ngModel)]="newUser.email" class="form-control"
            placeholder="E-mail Address  or Phone Number" required min="6" minlength="6" autofocus />
          <span>*</span>
        </div>
        <div *ngIf="resetPasswordError" class="form-label-group">
          <div style="padding: 4px 10px" class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>
              <i style="padding-right: 10px" class="fa fa fa-exclamation-triangle"></i>
            </strong>
            <label style="margin: 0; font-size: 0.9rem">User with <b>{{newUser.email}}</b> does not exist.</label>
          </div>
        </div>
        <button class="btn btn-lg btn-primary btn-block" type="submit">
          Reset Password
        </button>
        <label style="float: right; cursor: pointer; padding-top: 10px">
          <a style="text-decoration: none" (click)="forgotPaass = false" href="javascript:void(0)">
            <i style="font-size: 0.6rem; padding-right: 10px" class="fa fa-chevron-left"></i>
            I know my password
          </a>
        </label>
      </form>
      <!-- Forgot - Code - Ends -->
    </div>
  </div>
</div>

<div class="modal fade" id="resetPasswordMessage" tabindex="-1" role="dialog"
  aria-labelledby="resetPasswordMessageTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="margin-bottom: 0 !important; padding: 10px !important">
        <h5 class="modal-title" id="exampleModalLongTitle">Success</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [innerHTML]="resetPasswordMessage">
      </div>
      <div class="modal-footer">
        <button (click)="resetSuccessClick()" type="button" data-dismiss="modal" class="btn btn-primary">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<button style="display: none" id="resetPasswordModal" type="button" class="btn btn-primary" data-toggle="modal"
  data-target="#resetPasswordMessage"></button>

<div class="modal fade" id="singupErrorMessage" tabindex="-1" role="dialog" aria-labelledby="singupErrorMessageTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="
          margin-bottom: 0 !important;
          padding: 10px !important;
          color: var(--primary) !important;
        ">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <i style="padding-right: 10px" class="fa fa fa-exclamation-triangle"></i>
          Error
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span style="color: var(--primary)" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        User with given email or phone number already exists in the system.
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-primary">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="singupSuccessMessage" tabindex="-1" role="dialog" aria-labelledby="singupSuccessMessage"
  aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="
          margin-bottom: 0 !important;
          padding: 10px !important;
        ">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Success
        </h5>
      </div>
      <div class="modal-body">
        Hello <b> {{ newUser.firstName }} {{ newUser.lastName }}</b> thank you for
        registering with MyLOCALRX. You can now login to your account.
      </div>
      <div class="modal-footer">
        <button (click)="signIN = true" type="button" data-dismiss="modal" class="btn btn-primary">
          Sign In
        </button>
      </div>
    </div>
  </div>
</div>
</div>
<hr />