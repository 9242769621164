<div class="col-md-12">
    <div class="grid-container">
        <div class="grid-item" >
      <div style="font-size:20px; margin-top:30px;">     
        Relax your Mind, Body and Soul in a heavenly atmosphere, cleanse your stress away at the hands of our well experienced and trained staff.
         Enjoy the time away from your regular routine to help you recharge your body and soul.  
<br><br><br>
At our Wellness Spa, our goal is to give you a service that is distinctive from any other spa you've ever been to.
 Guests can expect nothing less than complete attention to their comfort and satisfaction from the time they enter the spa. 
 It is our understanding that you seek not just the pleasure of being pampered, but also a respite from the stresses of daily life. 

          
<br>

<hr>
<br>
<div style="text-align: center;">
    <button style="font-size: 18px;" class="spabutton" [routerLink]="['/appointment/covid/spa']" (click)="serviceName('spa')" ><svg xmlns="http://www.w3.org/2000/svg" width="41" height="30" fill="none" viewBox="0 0 51 35">
        <path fill="black" d="M0 25.463c0 .009 0 .009 0 0 0 1.01.813 1.831 1.822 1.831h28.355c1.01 0 1.823-.821 1.823-1.83V7.186H0v18.276zM23.94 9.018l.779.77-2.713 2.713-.77-.779 2.704-2.704zm-4.21 3.483c.42-.42 1.096-.411 1.506 0 .42.419.411 1.095 0 1.506l-.333.333-1.515-1.506.342-.333zm-2.55.855a.837.837 0 011.181 0l2.011 2.02a.837.837 0 010 1.18l-3.14 3.157-3.208-3.208 3.157-3.149zm-7.948 3.8a.542.542 0 01.77 0l1.677 1.676 1.575-1.566 3.208 3.209-1.566 1.574 1.677 1.677a.542.542 0 010 .77.542.542 0 01-.77 0l-2.9-2.9-1.883 1.89-.778-.77 1.882-1.89-1.215-1.215-1.677-1.677a.553.553 0 010-.779zm-.65 6.083c.53-.53 1.386-.53 1.916 0 .257.256.402.598.402.958 0 .744-.607 1.352-1.36 1.352-.36 0-.701-.146-.958-.394a1.353 1.353 0 010-1.916zM32 1.805A1.815 1.815 0 0030.177 0H27.21v2.533c0 .47-.386.855-.856.855a.858.858 0 01-.856-.855V0h-8.641v2.533c0 .47-.385.855-.856.855a.858.858 0 01-.856-.855V0H6.503v2.533c0 .47-.385.855-.856.855a.858.858 0 01-.856-.855V0H1.814A1.815 1.815 0 000 1.814v3.662h32v-3.67z"/>
        </svg> Book An Appointment Now </button> </div>
      </div>
    </div>      
        <div class="grid-item">
           
        <!-- {{ 'mainspa.png' | asset_img_url:'500x' | img_tag:"width: 100%;" }} -->
        <img style="width: 90%; margin-left:0px" src="../../assets/images/mainspa.png" />   
       
      </div>              
      </div>
    
      <br>
      <hr style="text-align:center">
<h2 style="text-align:center; margin-top:10px;"><strong>We offer a wide variety of spa services…</strong></h2>  <br>            

      <div class="grid-containersecond">
        <div class="grid-itemsecond">
            <!-- {{ 'bodytre.png' | asset_img_url:'500x' | img_tag:"width: 100%;" }} -->
            <img style="width: 100%; margin-left:0px" src="../../assets/images/bodytre.png" />   
                                                
                <h5 style="margin-top: 20px;"><b>Body Treatments</b></h5>       

      </div>
      <div class="grid-itemsecond">
        <!-- {{ 'bscrub.png' | asset_img_url:'500x' | img_tag:"width: 100%;" }} -->
        <img style="width: 100%; margin-left:0px" src="../../assets/images/bscrub.png" /> 
        <h5 style="margin-top: 20px;"><b>Body Scrubs</b></h5> 
        


  </div>
        <div class="grid-itemsecond">
           
        <!-- {{ 'bwrap.png' | asset_img_url:'500x' | img_tag:"width: 100%;" }} -->
        <img style="width: 100%; margin-left:0px" src="../../assets/images/bwrap.png" /> 
        <h5 style="margin-top: 20px;"><b>Body Wraps</b></h5> 
           
      </div>
      
      <div class="grid-itemsecond">
        <!-- {{ 'mtherapy.png' | asset_img_url:'500x' | img_tag:"width: 100%;" }} -->
        <img style="width: 100%; margin-left:0px" src="../../assets/images/mtherapy.png" /> 
        <h5 style="margin-top: 20px;"><b>Massage Therapy</b></h5> 
                        
  </div>
    <div class="grid-itemsecond">
       
    <!-- {{ 'fritual.png' | asset_img_url:'500x' | img_tag:"width: 100%;" }} -->
    <img style="width: 100%; margin-left:0px" src="../../assets/images/fritual.png" /> 
    <h5 style="margin-top: 20px;"> <b>Face Rituals</b> &nbsp; &nbsp;  </h5>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp; Many more...       
  </div>
      </div>

      <hr style="text-align:center">

      

      <h2 style="text-align:center"><strong>   Why Spa Treatments</strong></h2>  <br>  
     


      <div class="grid-containerthird">
        <div class="grid-itemthird">
            <!-- {{ 'whyspa.png' | asset_img_url:'500x' | img_tag:"width: 100%;" }} -->
            <img style="width: 90%; margin-left:0px" src="../../assets/images/whyspa.png" /> 
      </div>
        <div  style="font-size:22px;margin-top: 30px;" >
<ul style="list-style-type:circle;">
            <li>Relaxes the mind and body</li>
            <li>Helps to maintain your Blood Pressure</li>
            <li>Reduces Stress & Pain</li>
            <li>Chronic Pain Relief </li>
            <li>Improved Circulation </li>
            <li>Facial treatments </li>
            <li>Hair Removal </li>    
            </ul>  
      </div> 
      </div>
      <br>


    
    